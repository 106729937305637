
.coloredSectionWithVideo{
    max-width:80%;
    width: 100%;
    max-height: 100%;
    border-radius: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    background-color: #F89C7B;
    position: relative;
    z-index: 2223;
}

.coloredSectionContainer{
    display: flex;
    justify-content: center;
    width: 100%;
}


.coloredSection_topHead{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2px;    
}


.coloredSection_top{
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    gap: 2px;
    width: 80%;
    text-align: left;
}
.coloredSection_Content{
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
    gap: 2rem;
}
.coloredSection_video{
    max-width:532.23px;
    height: 300px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

}
.coloredSection_video video{
  
    width: 80%;
    height: auto;
    align-self: center;
}

@media screen and (max-width: 768px){
    .coloredSectionWithVideo{
        max-width: 100%;
        padding: 1rem;
        border-radius: 0px;
    }
    .coloredSection_Content{
        flex-flow: column;
        gap: 2px;
    
    }
    
    .coloredSection_top{
        padding: 1rem;
        gap: 10px;
    }
    .coloredSection_video video{
       aspect-ratio: 4/3;
    }
    .coloredSection_topHead h1{
        font-size: 1.6rem;
    }
    .coloredSection_topHead p{
        font-size: 0.9rem;
    }
}