.getinTouchSection{
  display: flex;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  padding: 2rem;
}
.getinTouchImage{
  width: 50%;
  height: auto;
}

.getinTouchform{
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 80%;
}
.getinTouchImage img{
    width: 80%;
    height: auto;
    align-self: center;
  }

.buttonStyle button{
  width: 100%;
  border-radius: 60px;
  background-color: #469AA2;
  color:white;
}
.buttonStyle button:hover{
  background-color: #469AA2;
  color:white;
}


@media screen and (max-width: 768px){
  .getinTouchSection{
    flex-direction: column;
    gap: 2rem;
  }
  .getinTouchImage img{
    width: 120%;
    height: auto;
    margin: 0 auto;
  }

}