
    .features{
        display: flex;
        justify-content: center;
        align-items: center;
        gap:2rem;
        flex-direction: column;
    }
    .featureSection{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 3rem;
        flex-direction: column;
        padding: 2rem;
        margin-top:-5rem;
    }
    .featuresBottomSection{
        margin-top: 10rem;
        display: flex;
        justify-content: center;
        align-items: center;
        gap:2rem;
        flex-direction: column;
    }
    .featureSectionContent{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 4rem;
        flex-direction: column;
    }
    .videoSectionInFeature{
        width: 100vw;
    }
  
    
@media screen and (min-width: 768px){

.features{
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.featureSection{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0;
}
.featuresBottomSection{
    flex-direction: row;
}
.featureBottomEndSection{
    margin-top:10rem;
}
.videoSectionInFeature{
    position: relative;
    margin-top: -8rem;
}


}

@media screen and (max-width: 1100px){
    .videoSectionInFeature{
        position: relative;
        margin-top: 0;
    }

}

