.header-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 12vh;
    padding: 0 20px;

}

.logo img {
    max-width: 80px;
    width: 100%;
    height: 80px;
}

.nav {
    display: flex;
    align-items: center;
}

.nav a {
    margin-right: 20px;
    color: #000;
    text-decoration: none;
    font-size: 16px;
}

.nav ul {
    display: flex;
    list-style: none;
}

.bar {
    width: 25px;
    height: 3px;
    background-color: #000;
    margin: 5px 0;
    transition: transform 0.3s ease-in-out;
}


.hamburger-menu {
    display: none;
}

/* New styles for mobile */
@media screen and (max-width: 600px) {
    
    .nav {
        display: none;
        flex-direction: column;
        position: fixed;
        top: 68px;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #fff;
        padding: 1rem;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    }

    .nav.open{
        display: flex;
        flex-flow: column nowrap;
         z-index:12312312321;
        margin: 0;
        justify-content: space-around;
        align-items: flex-start;
    }

    .nav.open ul{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        height: 50%;
        text-align: left;

        
    }
   

    .nav a {
        margin: 10px 0;
    }

    .hamburger-menu {
        display: block;
        cursor: pointer;
    }
   .open .bar:nth-child(1) {
        transform: rotate(-45deg) translate(-6px, 6px);
    }
     .open .bar:nth-child(3) {
        transform: rotate(45deg) translate(-6px, -6px);
    }
   .open .bar:nth-child(2) {
        opacity: 0;
    }
    body.open {
        overflow: hidden;
    }
    .bar {
        background-color: #000;
    }
    .logo img {
        max-width: 80px;
        height: auto;
    }
    
}
