
.profileContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.profileTop
.profileBottom{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.social-icons{
    display: flex;
    gap: 1rem;
    justify-content: center;
    align-items: center;
}

