/* profileImage.css */
.profile-image {
    max-width: 400px; 
    max-height: 300px; 
    position: relative; 
    overflow: hidden; 
    border-radius: 32px;
}
  .image-container {
    position: relative; 
    width: 100%;
    height: 100%;
  }
  
  .image {
    width: 100%; 
    height: 100%;
    display: block; 
    border-radius: 32px;
   
  }
  
  .image-text {
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;;
    height: 100%;
    transform: translate(-50%, -50%);
    border-radius: 32px;
    background-image: linear-gradient(to left, rgba(255, 218, 169, 0.90), rgba(248, 156, 123, 0.90)); /* Transparent linear gradient background */
    color: black;
    text-align: center;
    padding:1rem;
  }
  
  .profile-image:hover .image-text {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  